$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "@/colors";

#about-page {
  ol,
  ul {
    margin-left: 25px;
    margin-bottom: 10px;
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }

  a {
    color: $secondary-color;
  }
}
