$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "~@react-md/utils/dist/_variables";

@import "@/flatui-colors";
@import "@/tree";
@import "@/grid";

$section-margin: 1em;
$banner-padding: 3em;

#landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;

  // Override this so that the top-level justification tree has margin.
  .justification-tree > .chat-bubble.chat-bubble--positive {
    margin-right: $tree-branch-offset;
  }
  .justification-tree > .chat-bubble.chat-bubble--negative {
    margin-left: $tree-branch-offset;
  }

  .primary-slogan {
    font-size: 28px;
    margin-top: 3em;
    margin-bottom: 2em;
    font-weight: 300;
  }

  strong {
    font-weight: 400;
  }

  > p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
  }

  > * {
    margin-top: $section-margin;
    margin-bottom: $section-margin;
  }

  ul.blocks {
    display: flex;
    width: 66%;

    &,
    .rmd-icon,
    .material-symbols,
    a {
      color: $clouds;
    }

    .icon {
      text-align: center;
      margin-bottom: 16px;

      .rmd-icon,
      .material-symbols {
        font-size: 64px;
        text-shadow: none;
      }
    }

    li {
      flex: 1 1;

      margin-left: 0.5em;
      margin-right: 0.5em;

      font-size: 18px;
      text-shadow: 0 0 50px #666;
    }
  }

  .block-item-spacer {
    padding: 2.5em;
  }

  ul#ways-to-use {
    li:nth-child(1) {
      background-color: $peter-river;
      border: 1px solid $belize-hole;
    }
    li:nth-child(2) {
      background-color: darken($nephritis, 0%);
      border: 1px solid darken($nephritis, 5%);
    }
    li:nth-child(3) {
      background-color: $pumpkin;
      border: 1px solid $pomegranate;
    }
  }

  ul#get-started {
    li:nth-child(1) {
      background-color: $peter-river;
      border: 1px solid $belize-hole;
    }
    li:nth-child(2) {
      background-color: darken($nephritis, 0%);
      border: 1px solid darken($nephritis, 5%);
    }
    li:nth-child(3) {
      background-color: $pumpkin;
      border: 1px solid $pomegranate;
    }
  }

  .banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $banner-padding;
    padding-bottom: $banner-padding;
    background-color: lighten($peter-river, 43%);
  }

  @media (max-width: $rmd-utils-tablet-max-width) {
    > p,
    ul,
    .banner-content {
      width: 100%;
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }
    ul.blocks {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (min-width: $rmd-utils-desktop-min-width) and (max-width: $rmd-utils-large-desktop-min-width) {
    > p,
    .banner-content {
      width: 75%;
    }
    ul.blocks {
      width: 100%;
    }
  }

  @media (min-width: $rmd-utils-large-desktop-min-width) {
    > p,
    .banner-content {
      width: 50%;
    }
    ul.blocks {
      width: 66%;
    }
  }
}
