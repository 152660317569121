$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
.appearance-viewer {
  & > * {
    margin: 2em 0;
  }
  .entity-viewer {
    padding: 1em;
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #ccc;
  }
}
