$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "@/flatui-colors";
@import "@/colors";
@import "@/util";

.rmd-chip.has-vote .rmd-avatar.rmd-icon--before {
  color: $clouds;
  background-color: $agree-color;
}
.rmd-chip.has-antivote .rmd-avatar.rmd-icon--after {
  color: $clouds;
  background-color: $disagree-color;
}

.rmd-chip__content {
  margin-left: 5px;
  margin-right: 5px;
}

.rmd-chip {
  .rmd-avatar {
    color: $asbestos;
    background-color: $clouds;
  }
  .flipped-icon {
    font-size: 18px;
    @include flip-icon-horizontal;
  }
}
