$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "~@react-md/form/dist/mixins";

@import "../../colors";

@mixin placeholder {
  color: $asbestos;
}

#mainSearch {
  color: $light-text-color;

  /* Chrome doesn't render these styles if the rule includes non-chrome selectors.
     Just for safety, separate them all
   */

  /* WebKit, Blink, Edge */
  &::-webkit-input-placeholder {
    @include placeholder;
  }
  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    @include placeholder;
  }
  /* Mozilla Firefox 4 to 18 */
  &:-moz-placeholder {
    @include placeholder;
  }
  /* Mozilla Firefox 19+ */
  &::-moz-placeholder {
    @include placeholder;
  }
  &::placeholder {
    @include placeholder;
  }
  // Is this rule necessary?
  &:-moz-placeholder,
  &::-moz-placeholder {
    opacity: 0.5;
  }
}

#main-search-box-form {
  padding-left: 0.5rem;
  width: 100%;
}

#main-search--help-dialog {
  li {
    list-style-type: disc;
  }
  ul {
    margin: 10px 0;
  }
}

#mainSearch-combobox {
  // The search box is too far up. Increase top padding.
  @include rmd-form-theme-update-var(textarea-padding, 1.3rem);

  &.rmd-text-field-container--underline::after {
    background-color: darken($light-text-color, 20%);
    height: 1px;
  }
}

#header #mainSearch-listbox * {
  color: $primary-text-color;
}
