/** Variables overrides for react-md@2 */

@import "./colors";

$rmd-theme-light: true;
$rmd-theme-primary: $primary-color;
$rmd-theme-secondary: $secondary-color;
$rmd-theme-warning: $warning-color;
$rmd-theme-error: $error-color;
$rmd-theme-success: $success-color;
// AutoComplete items should look like cards.
$rmd-theme-surface: white;
$rmd-theme-light-background: $light-background-color;
$rmd-theme-light-surface: $light-surface-color;
$rmd-theme-dark-background: $dark-background-color;
$rmd-theme-dark-surface: $dark-surface-color;

$rmd-chip-outline-background-color: $light-surface-color;

// rmd-form-message on card was dark (#212121) but we want it to be light.)
// $rmd-theme-text-secondary-on-background depends on rmd-card-secondary-color, so
// we can override the color by setting that value.
$rmd-card-secondary-color: #757575;

$rmd-typography-font-family: "Lato", sans-serif;
$rmd-typography-desktop-max-line-length: 60em;
$rmd-typography-mobile-max-line-length: 40em;

$rmd-tab-indicator-color: $secondary-color;
