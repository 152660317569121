$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
.chat-bubble {
  .justification-tree > & {
    margin-left: 0;
    margin-right: 0;
  }
}

.justification-basis-viewer-row {
  display: flex;
  align-items: center;
  width: 100%;
  .justification-basis-viewer {
    flex-grow: 1;
  }
}
