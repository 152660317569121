$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./colors";
@import "./components";
@import "./grid";
@import "./util";

$slide-size: $icon-button-size;

.counter-justifications h3 {
  margin: $grid-gutter 0;
}
.chat-bubble > .counter-justifications {
  margin-bottom: $grid-gutter;
}
.chat-bubble--negative {
  & > .counter-justifications {
    margin-left: $grid-gutter;
    margin-right: $polarity-offset;
  }
}
.chat-bubble--positive {
  & > .counter-justifications {
    margin-left: $polarity-offset;
    margin-right: $grid-gutter;
  }
}

.justification-tree {
  .actions {
    // Hide the actions as they slide off-screen; else they create horizontal scroll bars
    // It would be nicer to do this as far out as possible, so as to avoid any clipping of the icons
    // but this works for now.  Adding this to the .justification-card is a problem because then the dropdown
    // menu is cipped.
    overflow: hidden;

    display: flex;

    // Create a local stacking context
    z-index: 0;

    align-items: center;

    & > * {
      transition: opacity 0.2s, right 0.4s, left 0.4s;
      position: relative;
      right: 0;

      &.verified,
      &.disverified {
        color: $midnight-blue;
        // Put the selected buttons (which will remain visible while others fade) above them
        z-index: 1;
      }
      &.disverified.inactive {
        right: $slide-size;
      }

      &.hiding {
        right: $slide-size;
        opacity: 0;
      }
    }
  }

  .otherSelected {
    .rmd-icon,
    .material-symbols {
      color: $concrete;
    }
  }

  .justification-status-text {
    text-align: right;
    display: inline-block;
    flex: 1 1;
  }

  &.positivey {
    & > * > .actions {
      .rmd-icon,
      .material-symbols {
        // When positivey, the icons make more sense flipped
        @include flip-icon-horizontal;
      }
    }
  }

  &.negativey {
    .justification-status-text {
      text-align: left;
    }

    & > * > .actions {
      justify-content: flex-start;
      direction: rtl;

      .rmd-icon,
      .material-symbols {
        transform: none;
      }

      & > * {
        left: 0;
        right: auto;

        &.disverified.inactive {
          left: $slide-size;
          right: auto;
        }

        &.hiding {
          left: $slide-size;
          right: auto;
          opacity: 0;
        }
      }
    }
  }
}
