@import "./colors";

// html for priority over built-in styles
html {
  .material-symbols {
    color: $icon-color;
    font-size: 20px;
  }
  button:disabled {
    .material-symbols {
      color: $disabled-icon-color;
    }
  }
}
