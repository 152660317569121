$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./flatui-colors";
@import "./colors";

#header {
  * {
    color: $light-text-color;
  }

  .mainSearchAutocomplete {
    margin-top: 10px;
    margin-left: 10px;
    padding-right: 20px;
  }
}

#main-search-container {
  flex-grow: 1;
}

#title {
  font-family: "Orbitron", sans-serif;
  font-weight: 400;

  // Set a width-relative size normally…
  font-size: 1.2vw;
  // …but below a certain width, stop shrinking the text
  @media (max-width: 768px) {
    font-size: 0.6em;
  }
}
