$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./colors";
@import "./tree";

.chat-bubble {
  background-color: #fff;
  .justification-tree:first-child > & {
    margin-top: 0;
  }
  border-radius: 0.4em;

  &.chat-bubble--positive {
    margin-right: $tree-branch-offset;
    border: 1px solid $entity-border-color;
  }
  &.chat-bubble--negative {
    margin-left: $tree-branch-offset;
    border: 1px solid $entity-border-color--negative;
  }
}
