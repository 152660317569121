$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./flatui-colors";

li.proposition-atom {
  // Position so that chat bubble offsets below position relative to this.
  position: relative;

  display: flex;
  align-items: center;
}

.proposition-atom {
  .entity-viewer {
    width: 100%;
  }
  &.highlighted,
  // override `border: none` from PropositionCompoundViewer
  &.highlighted:last-child {
    border: 2px solid $peter-river;
  }
}
