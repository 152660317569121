$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
.collapsable-text-ellipsis {
  font-weight: bold;
}

.collapsible-text-viewer {
  table {
    border-collapse: collapse;
  }

  th {
    font-weight: bold;
    border-bottom: 1px solid #666;
  }

  table,
  th,
  td {
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
