@import "colors";

@mixin react-md-fixes($grid-desktop-breakpoint, $grid-gutter) {
  .rmd-field-message-container {
    // Otherwise the fields are not full-width in the form.
    flex-grow: 1;
  }
  body .rmd-form-message {
    // This thing takes up way too much space.
    min-height: 0;
    margin-bottom: 0;
  }
}

.rmd-floating-label {
  // Override the black text to make it softer.
  color: rgba(0, 0, 0, 0.54);
}
