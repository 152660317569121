@import "./flatui-colors";

$primary-color: $midnight-blue;
$secondary-color: $alizarin;
$link-color: $belize-hole;

$primary-text-color: darken($midnight-blue, 15%);

$icon-color: darken($asbestos, 15%);
$disabled-icon-color: $silver;

$light-text-color: $clouds;
$deemphasized-text-color: $concrete;

$agree-color: #1abc9c;
$disagree-color: $alizarin;

$entity-border-color: $silver;
$entity-border-color--negative: lighten($disagree-color, 25%);

$success-color: $nephritis;
$warning-color: $sunflower;
$error-color: $pomegranate;

$light-background-color: $clouds;
$light-surface-color: $silver;
$dark-background-color: $midnight-blue;
$dark-surface-color: $wet-asphalt;
