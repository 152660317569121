$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "../colors";

button.show-source-description-help-dialog {
  padding: 0 5px;
  min-width: 0;
  text-align: center;
  vertical-align: middle;
}

.source-description-help-dialog {
  ul {
    margin: 10px;
    li {
      list-style-type: circle;
    }
  }
}

.media-excerpt-editor-fields {
  fieldset {
    border: 1px solid $entity-border-color;
    border-radius: 5px;
    padding: 20px;
  }
}
