$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "~@react-md/form/dist/mixins";

.api-autocomplete {
  .rmd-textarea {
    // The default text-height is too large.
    @include rmd-form-theme-update-var(text-height, 2rem);
  }
  .rmd-floating-label {
    // The label isn't far enough down.
    @include rmd-form-theme-update-var(floating-top, 1.7rem);
  }
}

.api-autocomplete-listbox {
  .rmd-list-item__text {
    // Don't truncate autocomplete results since they can be long and we want users to be able to see
    // their whole value for context.
    white-space: normal;
  }
}
