$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./colors";

#nav-tabs {
  display: flex;
  // center tabs
  justify-content: center;
  background-color: $primary-color;
}

.nav-tab {
  flex-basis: content;
  text-transform: uppercase;

  & > * {
    color: $light-text-color;
    // Apply the padding to the child so that the entire tab is clickable
    padding: 10px 25px;
    // Apply block so that the top/bottom padding has an effect.
    display: block;
  }

  &.active {
    border-bottom: 2px solid $secondary-color;
  }

  // Need a border-bottom for the transition out to work.
  border-bottom: 0px solid $secondary-color;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}
