// http://flatuicolors.com/
// https://material.io/color/#!/?view.left=1&view.right=0&primary.color=2c3e50&secondary.color=e74c3c&secondary.text.color=131c26&primary.text.color=ecf0f1

$turquoise: #1abc9c;
$green-sea: #16a085;
$emerald: #2ecc71;
$nephritis: #27ae60;

$peter-river: #3498db;
$belize-hole: #2980b9;
$wet-asphalt: #34495e;
$midnight-blue: #2c3e50;

$amethyst: #9b59b6;
$wisteria: #8e44ad;

$sunflower: #f1c40f;
$orange: #f39c12;
$carrot: #e67e22;
$pumpkin: #d35400;
$alizarin: #e74c3c;
$pomegranate: #c0392b;

$clouds: #ecf0f1;
$silver: #bdc3c7;
$concrete: #95a5a6;
$asbestos: #7f8c8d;
