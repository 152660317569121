$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./components";
@import "./colors";

.quote-text-expand-ellipsis {
  font-weight: bold;
}

.quote {
  //border-left: 2px solid $primary-color;
  margin: 0.3em 0 1em;
  padding: 0.5em 0;
}

.quote-text {
  margin-left: 20px;

  p:first-child:before,
  p:last-child:after {
    quotes: "\201C""\201D""\2018""\2019";
    color: $asbestos;
    font-size: 200%;
    position: absolute;
  }

  p:first-child:before {
    content: open-quote;
    margin-left: -15px;
  }
  p:last-child:after {
    content: close-quote;
    margin-left: 10px;
  }
}

.writ-quote-viewer {
  .writ-title {
    font-weight: 400;
  }

  ul.writ-quote-urls {
    margin-top: 0.8em;

    > li.url {
      margin: 0.2em $list-item-left-padding;

      @include material-icon-list-item("link");

      > a {
        .rmd-icon {
          vertical-align: middle;
          font-size: 12px;
          margin-left: 2px;
        }
      }
    }
  }
}
