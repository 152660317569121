$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "./reset";
@import "./util";
@import "./colors";
@import "./react-md";
@import "./react-material-symbols";

// TODO(#593) apply a dark theme https://v2.react-md.dev/guides/customizing-your-theme#conditional-dark-theme

html {
  background-color: $dark-background-color;
}

html,
html p {
  font-size: 15px;
}
html,
a {
  color: $primary-text-color;
}
p a {
  color: $link-color;
}
body {
  text-rendering: optimizeLegibility;
}
#app {
  background-color: $light-background-color;
}
body,
p {
  line-height: 20px;
}

.error-message,
.error-message li,
.error-message a {
  color: $error-color;
}

b {
  font-weight: 400;
}
em {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
}
h1 {
  font-size: 40px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 40px;
  margin-bottom: 14px;
}
h2 {
  font-size: 24px;
  margin-bottom: 14px;
}
h3 {
  font-size: 20px;
}
p {
  line-height: 20px;
  margin-bottom: 10px;
}
// When included in an iFrame (e.g. the extension) react-md's mixin doesn't seem to take precedence.
p,
textarea,
input {
  font-family: "Lato", sans-serif;
}

strong {
  font-weight: bold;
}

#webpack-hot-middleware-clientOverlay * {
  color: $light-text-color;
}

// This should be in react-md's _tooltips.scss, but it isn't loaded for some reason
.inline-rel-container {
  display: inline-block;
  margin: 1em;
  position: relative;
}

.app-nav-drawer-header {
  @include center-contents;
}

.cell--centered-contents {
  @include center-contents;
}

.clickable {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.selection-label {
  display: flex;
  align-items: center;

  .selection-label--text {
    margin-left: 5px;
  }
}

.entity-status-text {
  display: inline-block;
  color: $asbestos;
}

// A link within text that must be distinguished from it.
.text-link {
  text-decoration: underline;
}

#footer {
  background-color: $dark-background-color;
  color: $light-text-color;
  a {
    color: $link-color;
  }
  padding: 10px;
  text-align: center;

  #footer-links {
    :first-child {
      margin-right: 10px;
    }
    float: right;
  }
}

// Cards that are the top-level element in a grid cell should fill it. This
// allows us to use a separate component for the grid cell and the card, which
// allows us to use ReactFlip.
.rmd-grid__cell > .rmd-card {
  width: 100%;
  height: 100%;
}
