@import "./colors";
@import "./grid";

$list-item-left-padding: 1.5em;
$icon-button-size: 3rem;

@mixin material-icon-list-item($icon-name, $font-family: "Material Icons") {
  &:before {
    content: $icon-name;
    vertical-align: middle;
    color: $icon-color;

    margin-left: -1 * $list-item-left-padding;
    width: $list-item-left-padding;

    font-family: $font-family;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
  }
}
