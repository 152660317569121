$font-url-bebas-neue-thin: url(https://cdn.howdju.com/fonts/BebasNeue-Thin.otf);$font-url-bebas-neue-light: url(https://cdn.howdju.com/fonts/BebasNeue-Light.otf);$font-url-bebas-neue-book: url(https://cdn.howdju.com/fonts/BebasNeue-Book.otf);$font-url-bebas-neue-regular: url(https://cdn.howdju.com/fonts/BebasNeue-Regular.otf);$font-url-bebas-neue-bold: url(https://cdn.howdju.com/fonts/BebasNeue-Bold.otf);
@import "@/colors";
@import "@/flatui-colors";
@import "@/components";

.quotation {
  margin: 0.3em 0 1em;
  padding: 0.5em 0;

  margin-left: 20px;

  p:first-child:before,
  p:last-child:after {
    quotes: "\201C""\201D""\2018""\2019";
    color: $asbestos;
    font-size: 200%;
    position: absolute;
  }

  p:first-child:before {
    content: open-quote;
    margin-left: -15px;
  }
  p:last-child:after {
    content: close-quote;
    margin-left: 10px;
  }
}

ul.url-locators {
  margin-top: 0.8em;

  > li.url {
    margin: 0.2em $list-item-left-padding;

    @include material-icon-list-item("link");

    > a {
      // Force long URLs to break
      word-break: break-all;
      word-wrap: break-word;

      .rmd-icon,
      .material-symbols {
        vertical-align: middle;
        font-size: 12px;
        margin-left: 2px;
      }
    }
  }
}

ul.citations {
  margin-top: 0.8em;

  > li.citation {
    margin: 0.2em $list-item-left-padding;

    @include material-icon-list-item(
      "import_contacts",
      "Material Symbols Rounded"
    );
  }
}

ul.speakers {
  margin-top: 0.8em;

  > li.speaker {
    margin: 0.2em $list-item-left-padding;

    @include material-icon-list-item(
      "record_voice_over",
      "Material Symbols Rounded"
    );
  }
}

.material-symbols.auto-confirmation-icon {
  &.found {
    color: $nephritis;
  }
  &.previously-found {
    color: $sunflower;
  }
  &.never-found {
    color: $pomegranate;
  }
  &.never-tried {
    color: $asbestos;
  }
}
